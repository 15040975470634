<script>
export default {
    name:'LuckyDrawRule',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    data() {
        return {
            bool: true,
            monryResult: false,
            header:{
                mainTitle: getWord('lucky_draw_rules'),
            },
        }
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push('/home');
            } else {
                this.$router.go(-1);
            }
        }
    },
    mounted() {},
    watch: {}
}
</script>
<template>
    <div id="rule" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <mt-header :title="getWord('lucky_draw_rules')" style="height:0.88rem;font-size: 0.35rem;wdith:100%;">
                <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
            </mt-header>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
        </template>
        <div class="fontCss" style="margin-top:0.5rem">1.{{ getWord('each') }}<span>{{this.$route.query.jf}}</span>{{ getWord('lucky_draw_rules19') }}</div>
        <div class="fontCss">2.{{ getWord('lucky_draw_rules2') }}<span>{{ getWord('lucky_draw_rules3') }}</span>{{ getWord('lucky_draw_rules4') }}</div>
        <div class="fontCss">3.{{ getWord('lucky_draw_rules5') }}<span>1.8</span>{{ getWord('lucky_draw_rules6') }}<span>18888</span>{{ getWord('lucky_draw_rules7') }}</div>
        <div class="fontCss" style="margin-top:0.5rem">4.{{ getWord('lucky_draw_rules8') }}<span>{{ getWord('lucky_draw_rules9') }}</span>{{ getWord('lucky_draw_rules10') }}</div>
        <div class="fontCss" style="margin-top:0.5rem">5.{{ getWord('lucky_draw_rules11') }}<span>{{ getWord('lucky_draw_rules12') }}</span>{{ getWord('lucky_draw_rules13') }}</div>
        <div class="fontCss">6.{{ getWord('lucky_draw_rules14') }}</div>
        <div class="fontCss">7.{{ getWord('lucky_draw_rules15') }}<span>{{ getWord('lucky_draw_rules16') }}</span>{{ getWord('lucky_draw_rules17') }}</div>
        <div class="fontCss" style="margin-top:0.8rem">8.{{ getWord('lucky_draw_rules18') }}</div>
    </div>
</template>
<style scoped lang="scss">
#rule {

    &.template-3 {

        .fontCss {

            span {
                color: #EC2829;
            }
        }
    }

    .fontCss {
        float: left;
        width: 90%;
        font-size: 0.24rem;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        margin: 0.3rem 0 0 5%;

        span {
            color: #4796FC;
        }
    }
}

</style>
